import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "durationRange", "durationRangeLabel",
        "viewsRange", "viewsRangeLabel",
        "deletableByViewerCheckbox",
        "retrievalStepCheckbox"
     ]
    static values = {
        langD15minutes: String,
        langD30minutes: String,
        langD45minutes: String,
        langD1hour: String,
        langD6hours: String,
        langD12hours: String,
        langD1day: String,
        langD2days: String,
        langD3days: String,
        langD4days: String,
        langD5days: String,
        langD6days: String,
        langD1week: String,
        langD2weeks: String,
        langD3weeks: String,
        langD1month: String,
        langD2months: String,
        langD3months: String,

        langView: String,
        langViews: String,
        defaultViews: Number,

        langSave: String,
        langSaved: String,

        defaultRetrievalStep: Boolean,
        defaultDeletableByViewer: Boolean,
    }

    connect() { }

    updateDurationSlider(event) {
        let duration = event.target.value

        switch(duration) {
            case "0":
                this.durationRangeLabelTarget.innerText = this.langD15minutesValue;
                break;
            case "1":
                this.durationRangeLabelTarget.innerText = this.langD30minutesValue;
                break;
            case "2":
                this.durationRangeLabelTarget.innerText = this.langD45minutesValue;
                break;
            case "3":
                this.durationRangeLabelTarget.innerText = this.langD1hourValue;
                break;
            case "4":
                this.durationRangeLabelTarget.innerText = this.langD6hoursValue;
                break;
            case "5":
                this.durationRangeLabelTarget.innerText = this.langD12hoursValue;
                break;
            case "6":
                this.durationRangeLabelTarget.innerText = this.langD1dayValue;
                break;
            case "7":
                this.durationRangeLabelTarget.innerText = this.langD2daysValue;
                break;
            case "8":
                this.durationRangeLabelTarget.innerText = this.langD3daysValue;
                break;
            case "9":
                this.durationRangeLabelTarget.innerText = this.langD4daysValue;
                break;
            case "10":
                this.durationRangeLabelTarget.innerText = this.langD5daysValue;
                break;
            case "11":
                this.durationRangeLabelTarget.innerText = this.langD6daysValue;
                break;
            case "12":
                this.durationRangeLabelTarget.innerText = this.langD1weekValue;
                break;
            case "13":
                this.durationRangeLabelTarget.innerText = this.langD2weeksValue;
                break;
            case "14":
                this.durationRangeLabelTarget.innerText = this.langD3weeksValue;
                break;
            case "15":
                this.durationRangeLabelTarget.innerText = this.langD1monthValue;
                break;
            case "16":
                this.durationRangeLabelTarget.innerText = this.langD2monthsValue;
                break;
            case "17":
                this.durationRangeLabelTarget.innerText = this.langD3monthsValue;
                break;
            default:
                console.log("Error: duration value not found")
        }
    }

    updateViewsSlider(event) {
        let views = event.target.value
        if (views > 1) {
            this.viewsRangeLabelTarget.innerText = views + " " + this.langViewsValue;
        } else {
            this.viewsRangeLabelTarget.innerText = views + " " + this.langViewValue;
        }
    }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'payloadBlur' ]

    static values = { }

    initialize() { }

    connect() { }

    toggleBlur(event) {
        const isBlurred = this.payloadBlurTarget.classList.contains('blur');
        if (isBlurred) {
          this.payloadBlurTarget.classList.remove('blur');
        } else {
          this.payloadBlurTarget.classList.add('blur');
        }
    }
  }

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'answerBox'
  ];

  toggleAll(event) {
    let firstAnswer = this.answerBoxTargets[0];
    if (firstAnswer.classList.contains('hidden')) {
      this.answerBoxTargets.forEach((answer) => {
        answer.classList.remove('hidden');
      })
    } else {
      this.answerBoxTargets.forEach((answer) => {
        answer.classList.add('hidden');
      })
    }
  }

  toggleAnswer(event) {
    let currentNode = event.target;
    let question = null;

    // Check if the current node is an h3 element
    if (currentNode.tagName === 'BUTTON') {
      question = currentNode;
    } else {
      // If not, search up to 3 levels higher for an h3 element
      for (let i = 0; i < 3; i++) {
        currentNode = currentNode.parentNode;
        if (currentNode.tagName === 'BUTTON') {
          question = currentNode;
          break;
        }
      }
    }

    if (question) {
      const answer = question.nextElementSibling;
      if (answer.classList.contains('hidden')) {
        answer.classList.remove('hidden');
      } else {
        answer.classList.add('hidden');
      }
    }
  }
}
